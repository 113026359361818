// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';
// import './login.css';
// import logo from './icons/logo.png';
// import vThink from './icons/vThink.png';
// import { jwtDecode } from "jwt-decode";

// const Login = () => {
//   const [currentImageIndex, setCurrentImageIndex] = useState(0);
//   const [googleLoginStatus, setGoogleLoginStatus] = useState('');

//   const navigate = useNavigate();

//   const carouselImages = [
//     {
//       img: "./Confirmed attendance-pana.png",
//       name: "Attendance Report",
//       description: "Streamline attendance tracking and ensure accurate maintenance of records and historical data."
//     },
//     {
//       img: "./Upload-pana.png",
//       name: "Upload",
//       description: "Facial recognition, time management tool and Greythr are diverse applications spanning biometric identification, productivity optimization, and human resources management, respectively."
//     },
//     {
//       img: "./Research paper-rafiki.png",
//       name: "Research",
//       description: "Efficiently manage and analyze employee data through table view interfaces, generating detailed reports for workforce management"
//     }
//   ];

//   const handleLogin = (googleUser) => {

//     // Decode the JWT credential
//     let decoded = jwtDecode(googleUser?.credential);

//     // Extract user-related information from the decoded payload
//     const email = decoded?.email;
//     const name = decoded?.name;
//     const googleId = decoded?.sub; // Assuming 'sub' contains the Google ID
//     const token = googleUser?.tokenId;

//     const result = { email, name, googleId, token };

//     console.log(result);
//     const allowedEmails = ['dhineshkumar@vthink.co.in','satheesh@vthink.co.in',
//     'anand.robertson@vthink.co.in', 'ramani@vthink.co.in'];

//     // Check if the user information is available
//     if (email && allowedEmails.includes(email)) {
//       setGoogleLoginStatus('Google Login successful');
//       navigate('/home', { state: { username: name } });
//     } else {
//       setGoogleLoginStatus('Unauthorized access');
//     }
//   };




//   const { signIn, signOut } = useGoogleLogin({
//     clientId: '533464289026-aqhqp4009mmee91l0asu57t8u38cfihn.apps.googleusercontent.com', // Replace with your actual client ID
//     onSuccess: handleLogin, // Use handleLogin directly here
//     onFailure: (error) => {
//       console.error('Google Login Failure:', error);
//       setGoogleLoginStatus('Google Login failed');
//     },
//   });

//   const handleNextImage = () => {
//     setCurrentImageIndex((prevIndex) => (prevIndex + 1) % carouselImages.length);
//   };

//   const handlePrevImage = () => {
//     setCurrentImageIndex((prevIndex) => (prevIndex - 1 + carouselImages.length) % carouselImages.length);
//   };

//   useEffect(() => {
//     const intervalId = setInterval(() => {
//       handleNextImage();
//     }, 2000);

//     return () => clearInterval(intervalId);
//   }, [currentImageIndex]);

//   return (
//     <div style={{ backgroundColor: "#e9f2f7" }}>
//       <div className='login'>
//         <div className='loginPage'>
//           <div style={{ paddingBottom: '50px' }}>
//             <img className='vThink' src={vThink} alt="vThink" />
//           </div>

//           <div className='nextbox'>
//             <div className="loginlogo">
//               <img src={logo} alt="logo" />
//             </div>

//             <GoogleLogin
//             buttonText="Login with Google"
//             onSuccess={handleLogin} // Use handleLogin directly here
//             onFailure={(error) => {
//               console.error('Google Login Failure:', error);
//               setGoogleLoginStatus('Google Login failed');
//             }}
//           />

//             <p className='loginStatus'>{googleLoginStatus}</p>
//           </div>
//         </div>
//         <div className="carasol">
//           <img className='carasol' src={carouselImages[currentImageIndex].img} alt="carousel" />
//           <div className="carousel-description">
//             {/* <p style={{ display: "flex", justifyContent: "center", color: "#484B4D" }}>{carouselImages[currentImageIndex].description}</p> */}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Login;


import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from './authConfig.js'; // Make sure to create authConfig.js file with loginRequest
import './login.css';
import logo from './icons/logo.png';
import vThink from './icons/vThink.png';
import { jwtDecode } from 'jwt-decode';

const Login = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [msalLoginStatus, setMsalLoginStatus] = useState('');
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();


  const carouselImages = [
    {
      img: "./Confirmed attendance-pana.png",
      name: "Attendance Report",
      description: "Streamline attendance tracking and ensure accurate maintenance of records and historical data."
    },
    {
      img: "./Upload-pana.png",
      name: "Upload",
      description: "Facial recognition, time management tool and Greythr are diverse applications spanning biometric identification, productivity optimization, and human resources management, respectively."
    },
    {
      img: "./Research paper-rafiki.png",
      name: "Research",
      description: "Efficiently manage and analyze employee data through table view interfaces, generating detailed reports for workforce management"
    }
  ]

  const handleLogin = async () => {
    try {
      const loginResponse = await instance.loginPopup(loginRequest);
  
      // Decode the JWT token
      let decoded = jwtDecode(loginResponse.idToken);
  
      // Extract user-related information from the decoded payload
      const email = decoded?.preferred_username; // Assuming 'preferred_username' contains the email
      const name = decoded?.name;
      const microsoftId = decoded?.oid; // Assuming 'oid' contains the Microsoft ID
      const token = loginResponse.accessToken;
  
      const result = { email, name, microsoftId, token };
  
      console.log(result);
  
      // Check if the user information is available and if the email is allowed
      const allowedEmails = ['ramani@vthink.co.in', 'anand.robertson@vthink.co.in', 'satheesh@vthink.co.in','dhineshkumar@vthink.co.in', 'logeswaran@vthink.co.in', 'vignesh.dl@vthink.co.in'];
      if (email && allowedEmails.includes(email)) {
        setMsalLoginStatus('Microsoft Login successful');
        console.log("success");
        navigate('/home', { state: { username: name } });
      } else {
        setMsalLoginStatus('Unauthorized access');
      }
    } catch (error) {
      console.error('Microsoft Login Failure:', error);
      setMsalLoginStatus('Microsoft Login failed');
    }
  };
  

    const handleNextImage = () => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % carouselImages.length);
    };

    const handlePrevImage = () => {
      setCurrentImageIndex((prevIndex) => (prevIndex - 1 + carouselImages.length) % carouselImages.length);
    };

    useEffect(() => {
      const intervalId = setInterval(() => {
        handleNextImage();
      }, 2000);

      return () => clearInterval(intervalId);
    }, [currentImageIndex]);

    return (
      <div style={{ backgroundColor: '#e9f2f7' }}>
        <div className='login'>
          <div className='loginPage'>
            <div style={{ paddingBottom: '50px' }}>
              <img className='vThink' src={vThink} alt='vThink' />
            </div>

            <div className='nextbox'>
              <div className='loginlogo'>
                <img src={logo} alt='logo' />
              </div>

              <button onClick={handleLogin}>Login with Microsoft</button>

              <p className='loginStatus'>{msalLoginStatus}</p>
            </div>
          </div>
          <div className="carasol">
            <img className='carasol' src={carouselImages[currentImageIndex].img} alt="carousel" />
            <div className="carousel-description">
              {/* <p style={{ display: "flex", justifyContent: "center", color: "#484B4D" }}>{carouselImages[currentImageIndex].description}</p> */}
            </div>
          </div>
        </div>
      </div>
    );
  };

  export default Login;



